import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Image from "gatsby-image";
import classNames from "classnames";
import Layout from "../components/Layout";
import GridHelper from "../components/GridHelper";
import Link from "../components/Link";
import scrollTo from "../utils/scrollTo";
import { windowHeight } from "../utils/windowDimensions";
import LeftArrow from "../components/Arrows/Left-arrow";
import RightArrow from "../components/Arrows/Right-arrow";

const WorkSingleTemplate = ({ pageContext, location }) => {
  useEffect(() => {
    let timer;
    const startScroll = () => {
      timer = setTimeout(() => {
        if (window.scrollY === 0) {
          scrollTo(windowHeight);
        }
      }, 2500);
    };
    const stopScroll = () => {
      clearTimeout(timer);
    };
    startScroll();
    return () => {
      stopScroll();
    };
  }, []);

  const { data: templateData, next: nextData, prev: prevData } = pageContext;
  return (
    <Layout
      seoTitle={templateData.meta_title || templateData.title || "Work"}
      seoDescription={templateData.meta_description}
      location={location}
      hasMenuIcon
      hasLightTheme
    >
      {templateData.show_layout_grid && <GridHelper />}
      <div className="relative h-screen mb-ogs hidden lg:block">
        {templateData.hero_image.fluid ? (
          <Image
            className="w-full h-full relative bg-grey-light z-10"
            placeholderStyle={{ display: "none" }}
            fluid={{
              ...templateData.hero_image.fluid,
              aspectRatio: 0
            }}
            loading="eager"
          />
        ) : (
          <div className="w-full h-full bg-grey-light relative" />
        )}
      </div>
      <div className="relative h-screen mb-ogs lg:hidden">
        {templateData.hero_image_mobile.fluid ? (
          <Image
            className="w-full h-full relative bg-grey-light z-10"
            placeholderStyle={{ display: "none" }}
            fluid={{
              ...templateData.hero_image_mobile.fluid,
              aspectRatio: 0
            }}
            loading="eager"
          />
        ) : (
          <div className="w-full h-full bg-grey-light relative" />
        )}
      </div>
      <div className="mx-ogs">
        <div className="grid grid-cols-12 gap-gs mb-ogs">
          {templateData.intro_image.fluid && (
            <div className="hidden md:block col-span-6">
              <Image
                className="bg-grey-light"
                placeholderStyle={{ display: "none" }}
                fluid={templateData.intro_image.fluid}
                loading="eager"
              />
            </div>
          )}
          <div
            className={`space-y-4 lg:space-y-6 py-ogs ${
              templateData.intro_image.fluid
                ? "col-span-12 md:col-span-6"
                : "col-span-12"
            }`}
          >
            {templateData.title && (
              <h1 className="text-lg lg:text-xl xl:text-2xl leading-relaxed">
                {templateData.title}
              </h1>
            )}
            {templateData.intro_body.html && (
              <div
                className="max-w-6xl text-base md:text-lg lg:text-xl leading-relaxed space-y-6"
                dangerouslySetInnerHTML={{
                  __html: templateData.intro_body.html
                }}
              />
            )}
          </div>
        </div>
      </div>

      <div className="mb-ogs">
        {templateData.image_row
          .filter(item => item.image_one.fluid || item.image_two.fluid)
          .map((item, index) => {
            // make parent container class list
            let parentContainerClassList = [""];
            if (item.container_size !== "Full Width Full Bleed") {
              parentContainerClassList.push("mx-ogs");
            }
            if (item.space_below === "Double") {
              parentContainerClassList.push("mb-ogsd");
            } else {
              parentContainerClassList.push("mb-ogs");
            }
            if (item.display === "Hide on Desktop") {
              parentContainerClassList.push("block md:hidden");
            }
            if (item.display === "Hide on Mobile") {
              parentContainerClassList.push("hidden md:block");
            }
            // make inner container class list
            let innerContainerClassList = ["pl-gs"];
            if (item.container_size === "Reduced Width") {
              innerContainerClassList.push("w-full md:w-10/12");
            } else {
              innerContainerClassList.push("w-full");
            }
            // make images container class list
            let imagesContainerClassList = ["flex flex-wrap -mt-ogs -ml-gs"];
            if (item.image_alignment === "Left") {
              imagesContainerClassList.push("justify-start");
            }
            if (item.image_alignment === "Right") {
              imagesContainerClassList.push("justify-end");
            }
            if (item.image_alignment === "Centre") {
              imagesContainerClassList.push("justify-center");
            }
            // make image container class list
            let imageContainerClassList = ["pt-ogs pl-gs"];
            if (item.image_two.fluid || item.image_alignment !== "None") {
              imageContainerClassList.push("w-full md:w-1/2");
            } else {
              imageContainerClassList.push("w-full");
            }

            return (
              <div key={index} className={classNames(parentContainerClassList)}>
                <div className="flex justify-center -ml-gs">
                  <div className={classNames(innerContainerClassList)}>
                    <div className={classNames(imagesContainerClassList)}>
                      {item.image_one.fluid && (
                        <div className={classNames(imageContainerClassList)}>
                          <Image
                            className="bg-grey-light"
                            placeholderStyle={{ display: "none" }}
                            fluid={item.image_one.fluid}
                            loading="eager"
                          />
                        </div>
                      )}
                      {item.image_two.fluid && (
                        <div className={classNames(imageContainerClassList)}>
                          <Image
                            className="bg-grey-light"
                            placeholderStyle={{ display: "none" }}
                            fluid={item.image_two.fluid}
                            loading="eager"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <div className="px-ogs">
        {templateData.credits.length > 0 && (
          <div className="flex flex-col md:items-end mb-ogs">
            {templateData.credits
              .filter(item => item.credit)
              .map((item, index) => {
                if (item.link.url) {
                  return (
                    <a
                      className={`link text-sm md:text-base ${
                        item.space_below ? "mb-6" : "mb-2"
                      }`}
                      key={index}
                      href={item.link.url}
                    >
                      {item.credit}
                    </a>
                  );
                } else {
                  return (
                    <div
                      className={`text-sm md:text-base ${
                        item.space_below ? "mb-6" : "mb-2"
                      }`}
                      key={index}
                    >
                      {item.credit}
                    </div>
                  );
                }
              })}
          </div>
        )}
      </div>
      <div className="p-ogs mb-ogs flex items-center">
        <div className="w-1/2">
          {prevData && (
            <Link
              className="flex items-center w-8 h-8 md:w-10 md:h-10 gap-x-3 pb-1 pt-2"
              to={`/work/${prevData.uid}/`}
              aria-label="Previous Project"
            >
              <LeftArrow />
            </Link>
          )}
        </div>
        <div className="flex justify-end w-1/2">
          {nextData && (
            <Link
              className="flex items-center justify-end w-8 h-8 md:w-10 md:h-10 gap-x-3 pb-1 pt-2"
              to={`/work/${nextData.uid}/`}
              aria-label="Next Project"
            >
              <RightArrow />
            </Link>
          )}
        </div>
      </div>
    </Layout>
  );
};

WorkSingleTemplate.propTypes = {
  location: PropTypes.object.isRequired
};

export default WorkSingleTemplate;
