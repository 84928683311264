import React from "react";

const GridHelper = () => {
  return (
    <div className="mx-ogs fixed inset-0 pointer-events-none">
      <div className="grid-helper">
        <div className="grid-helper__inner">
          {[...Array(12)].map((item, index) => {
            return (
              <div
                key={index}
                className={`grid-helper__item ${
                  index > 3 ? "hidden md:block" : ""
                }`}
              >
                <div className="grid-helper__item-inner"></div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default GridHelper;
